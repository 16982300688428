// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-features-tsx": () => import("/opt/build/repo/src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("/opt/build/repo/src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-review-index-tsx": () => import("/opt/build/repo/src/pages/review/index.tsx" /* webpackChunkName: "component---src-pages-review-index-tsx" */),
  "component---src-pages-review-verify-tsx": () => import("/opt/build/repo/src/pages/review/verify.tsx" /* webpackChunkName: "component---src-pages-review-verify-tsx" */),
  "component---src-pages-solutions-accountants-tsx": () => import("/opt/build/repo/src/pages/solutions/accountants.tsx" /* webpackChunkName: "component---src-pages-solutions-accountants-tsx" */),
  "component---src-pages-solutions-estate-agents-tsx": () => import("/opt/build/repo/src/pages/solutions/estate-agents.tsx" /* webpackChunkName: "component---src-pages-solutions-estate-agents-tsx" */),
  "component---src-pages-solutions-hair-and-beauty-tsx": () => import("/opt/build/repo/src/pages/solutions/hair-and-beauty.tsx" /* webpackChunkName: "component---src-pages-solutions-hair-and-beauty-tsx" */),
  "component---src-pages-solutions-restaurants-tsx": () => import("/opt/build/repo/src/pages/solutions/restaurants.tsx" /* webpackChunkName: "component---src-pages-solutions-restaurants-tsx" */),
  "component---src-pages-trust-tsx": () => import("/opt/build/repo/src/pages/trust.tsx" /* webpackChunkName: "component---src-pages-trust-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

